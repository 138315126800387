import Chem from '../images/glogo/glogo_chem.png'
import Dais from '../images/glogo/glogo_dais.png'
import Elis from '../images/glogo/glogo_elis.png'
import Fchpt from '../images/glogo/glogo_fchpt.png'
import Igc from '../images/glogo/glogo_igc.png'
import Intech from '../images/glogo/glogo_intech.png'
import Itcrab from '../images/glogo/glogo_itcrab.png'
import Lumacol from '../images/glogo/glogo_lumacol.png'
import Marbach from '../images/glogo/glogo_marbach.png'
import Patak from '../images/glogo/glogo_patak.png'
import Peelo from '../images/glogo/glogo_peelo.png'
import Ppc from '../images/glogo/glogo_ppc.png'
import Sjf from '../images/glogo/glogo_sjf.png'
import Smartcad from '../images/glogo/glogo_smartcad.png'
import Tierra from '../images/glogo/glogo_tierra.png'

export const GlogoCarousel = () => {
	const images = [
		Chem,
		Dais,
		Elis,
		Fchpt,
		Igc,
		Intech,
		Itcrab,
		Lumacol,
		Marbach,
		Patak,
		Peelo,
		Ppc,
		Sjf,
		Smartcad,
		Tierra,
	]

	return (
		<div className="relative flex overflow-x-hidden">
			<div className="flex animate-marquee whitespace-nowrap py-8">
				{images.map((image, index) => (
					<img
						src={image}
						alt={`Logo ${index + 1}`}
						className="mx-4 w-[150px] md:mx-8"
						key={`${image}-${index}`}
					/>
				))}
			</div>
		</div>
	)
}
